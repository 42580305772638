<template>
	<Dialog
		v-model:visible="cookiesVisible"
		:position="position"
		class="cookies"
		:style="{ 'max-width': '50vw' }">
		<template #header>
			<h2 class="text-3xl">{{ $t("global.cookies-title") + " " + url.hostname }}</h2>
		</template>

		<span class="text-base"> {{ url.hostname + " " + $t("global.cookies-text") }}</span>

		<template #footer>
			<Button
				:label="$t('global.accept-close')"
				@click="acceptCookies()" />
		</template>
	</Dialog>
</template>

<script setup>
	import { useCookie } from "#app";

	const route = useRoute();
	const acceptCookiesValue = useCookie("user-accept-cookies");
	const url = useRequestURL();

	const position = ref("bottomleft");

	const cookiesVisible = ref(true);

	function acceptCookies() {
		acceptCookiesValue.value = "1";
		setVisibleCookies(false);
	}

	function setVisibleCookies(value) {
		cookiesVisible.value = value;
	}

	watchEffect(() => {
		setVisibleCookies(route.meta.cookiesVisible);
	});
</script>

<style lang="scss">
	@import "cookies";
</style>
